




















































































import axios from "axios";

import { Component, Prop, Vue } from "vue-property-decorator";
import ListToolbar from "@/components/ListToolbar.vue";
import PlanListItem from "@/components/PlanListItem.vue";

import { Plan, Module } from "@/scripts/fp2";

@Component({
  components: {
    ListToolbar,
    PlanListItem
  }
})
export default class Plans extends Vue {
  public stillLoading: boolean = true;

  public plans: Plan[] = [];

  public search: string = "";
  public sortBy: string = "";
  public sortKeys: string[] = [
    "planName",
    "isTemplate",
    "customer.department",
    "customer.province.shortName",
    "lastEdit"
  ];

  get sortDesc() {
    return this.sortBy === "isTemplate" || this.sortBy === "lastEdit";
  }

  public async newPlan() {
    const planID = await axios
      .put("/api/plans/full", new Plan().intoJson())
      .then(resp => resp.data);

    this.$router.push(`/plans/${planID}`);
  }

  public async getPlans() {
    this.stillLoading = true;

    const plansJson = await axios.get(`/api/plans/full`).then(resp => {
      return resp.data;
    });

    const percent = await axios.get("/api/service_percent").then((resp) => resp.data);

    const plans = [];
    for (const planJson of plansJson) {
      plans.push(Plan.fromJson(planJson, percent));
    }
    // type assertion because typescript is dumb and can't tell that filter removes all the nulls
    this.plans = plans.filter(val => val !== null) as Plan[];
    this.plans.sort((a, b) => (a.lastEdit < b.lastEdit ? 1 : -1));

    this.stillLoading = false;
  }

  private async created() {
    await this.getPlans();
  }
}






























import { Component, Vue } from "vue-property-decorator";
import ListToolbar from "@/components/ListToolbar.vue";
import ModuleListItem from "@/components/ModuleListItem.vue";

import axios from "axios";

import { Module } from "@/scripts/fp2";

@Component({
  components: {
    ListToolbar,
    ModuleListItem
  }
})
export default class Modules extends Vue {
  public stillLoading: boolean = true;

  public modules: Module[] = [];

  public async newModule() {
    const all_prov_ids = await axios.get(`/api/provinces`).then((resp) => resp.data.map((p: any) => p.id));

    const modID = await axios
      .put("/api/modules/full", new Module({
        allowedProvinces: all_prov_ids,
      }).intoJson())
      .then(resp => resp.data);

    this.$router.push(`/modules/${modID}`);
  }

  public async getModules() {
    this.stillLoading = true;

    const modsJson = await axios.get("/api/modules/full").then(resp => resp.data);
    const percent = await axios.get("/api/service_percent").then((resp) => resp.data);

    let modules = [];
    for (const modJson of modsJson) {
      modules.push(Module.fromJson(modJson, percent));
    }

    modules.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );

    this.modules = modules;

    this.stillLoading = false;
  }

  async created() {
    await this.getModules();
  }
}

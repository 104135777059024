var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',{style:(_vm.plan.isTemplate ? 'background-color: var(--v-highlight-base)' : '')},[_c('v-list-item-content',[(_vm.plan.isTemplate)?_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-star")]),_vm._v(" "+_vm._s(_vm.plan.planName || "Unnamed Template")+" ")],1):_c('v-list-item-title',[_vm._v(_vm._s(_vm.plan.planName || "Unnamed Plan"))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.plan.customer ? _vm.plan.customer.department + ", " + (_vm.plan.customer.province ? _vm.plan.customer.province.shortName : "No Province") : "No Customer"))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.plan.modules.length)+" Modules: $"+_vm._s(_vm.plan.discountedPrice)+" + $"+_vm._s(_vm.plan.discountedService)+"/yr ")]),_c('v-list-item-subtitle',[_vm._v(" Last Changed: "+_vm._s(_vm.plan.lastEdit.toLocaleString("en-CA"))+" ")])],1),_c('v-list-item-action',[_c('v-layout',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-flex',{staticClass:"mx-2"},[_c('v-icon',_vm._g({on:{"click":_vm.viewQuote}},on),[_vm._v("mdi-format-quote-open")])],1)]}}])},[_c('span',[_vm._v("Go to Quote")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-flex',{staticClass:"mx-2"},[_c('v-icon',_vm._g({on:{"click":_vm.editPlan}},on),[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Edit Plan")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-flex',{staticClass:"mx-2"},[_c('v-icon',_vm._g({on:{"click":_vm.archivePlan}},on),[_vm._v("mdi-folder-lock")])],1)]}}])},[_c('span',[_vm._v("Archive Plan")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-flex',{staticClass:"mx-2"},[_c('v-icon',_vm._g({on:{"click":_vm.deletePlan}},on),[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Delete Plan")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
































import { Component, Vue } from "vue-property-decorator";
import Navbar from "./components/Navbar.vue";
import axios from "axios";

@Component({
  components: {
    Navbar
  }
})
export default class App extends Vue {
  public isAdmin: boolean = true;
  public version: string = "unknown";

  public origin() {
    return window.location.origin;
  }

  public async created() {
    this.version = await axios.get(`/api/version`).then((resp) => resp.data);
  }
}

import axios from "axios";
import { Province } from "./province";

export interface CustomerConstructorOptions {
  id?: number | null,
  department?: string,
  contactName?: string,
  contactEmail?: string,
  province?: Province | null,
}

export class Customer {
  public id: number | null;
  public department: string;
  public contactName: string;
  public contactEmail: string;
  public province: Province | null;

  constructor(
    options?: CustomerConstructorOptions
  ) {
    this.id = options?.id ?? null;
    this.department = options?.department ?? "New Customer";
    this.contactName = options?.contactName ?? "";
    this.contactEmail = options?.contactEmail ?? "";
    this.province = options?.province ?? null;
  }

  public static async getById(id: number): Promise<Customer> {
    const custJson = await axios
      .get(`/api/customers/${id}`)
      .then(resp => resp.data);

    return Customer.fromJson(custJson);
  }

  public static fromJson(custJson: any): Customer {
    return new Customer({
      id: custJson.id,
      department: custJson.department,
      contactName: custJson.contact_name,
      contactEmail: custJson.contact_email,
      province: custJson.province ? Province.fromJson(custJson.province) : null,
    })
  }

  public intoJson(): any {
    return {
      id: this.id,
      department: this.department,
      contact_name: this.contactName,
      contact_email: this.contactEmail,
      province: this.province ? this.province.intoJson() : null,
    }
  }

  public async save() {
    await axios.put(`/api/customers/full`, this.intoJson())
  }
}

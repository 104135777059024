


























































import axios from "axios";

import { Component, Vue } from "vue-property-decorator";
import { Customer, Province } from "@/scripts/fp2";

@Component
export default class CustomerView extends Vue {
  public myMod = this.$store.state.myMod;

  public stillLoading: boolean = true;
  public customer: Customer = new Customer();

  public allProvinces: Province[] = [];

  public async getProvinces() {
    const provinces = await axios.get(`/api/provinces`).then(resp => resp.data);
    for (const provinceJson of provinces) {
      this.allProvinces.push(Province.fromJson(provinceJson));
    }
  }

  public async getCustomer() {
    this.stillLoading = true;
    const id = this.$route.params.customerId;
    this.customer = await Customer.getById(parseInt(id, 10));
    this.stillLoading = false;
  }

  public async saveCustomer(): Promise<void> {
    await this.customer.save();
    this.$router.go(-1);
  }

  async created() {
    await this.getCustomer();
    await this.getProvinces();
  }
}

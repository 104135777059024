



























































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";

@Component
export default class Login extends Vue {
  public password: string = "";

  public loginFailedAlert: boolean = false;

  public isLoggingIn: boolean = false;

  public login() {
    this.isLoggingIn = true;

    axios
      .post(`/api/login`, JSON.stringify(this.password), {
        headers: { "Content-Type": "application/json" }
      })
      .then(resp => {
        this.$router.push("/");
        this.isLoggingIn = false;
      })
      .catch(resp => {
        this.loginFailedAlert = true;
        this.isLoggingIn = false;
      });
  }
}


























import { Component, Prop, Vue } from "vue-property-decorator";
import { Customer } from "@/scripts/fp2";

import axios from "axios";

// This is a decorator for the class Home, not a seperate line
@Component
export default class CustomerListItem extends Vue {
  @Prop() public customer!: Customer;

  // public editPath: string = "/customers/" + this.customer.id;

  public edit(): void {
    window.alert("woohoo!");
  }

  public async deleteItem(): Promise<void> {
    await axios.delete(`/api/customers/${this.customer.id}`).catch(error => {
      if (error.response.status === 409 /* conflict */) {
        alert(
          "Cannot delete this customer because plans still exist that are attached to them"
        );
      }
    });

    this.$emit("item-delete");
  }
}






























































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Customer, Province } from "@/scripts/fp2";

import axios from "axios";

@Component
export default class CustomerSummary extends Vue {
  public myMod = this.$store.state.myMod;

  /** The customer we are showing, defaults to null */
  @Prop({default: null}) public customer!: Customer | null;

  /** flag for whether the change customer dialog is open */
  public changeCustomerDialogOpen: boolean = false;

  /** Filter string for filtering customer list */
  public customerFilter: string = "";

  /** Array of all customers filtered by customerFilter and also not the current customer */
  get filteredCustomers() {
    return this.allCustomers
      // 'ZZ' is used here when there is no province on the customer and means they
      // should be sorted to the end of the list instead of to 'N' for null
      .filter(customer => {
        const matchesFilter = `${customer.department}, ${customer.province?.shortName ?? 'ZZ'}`
          .toLowerCase()
          .includes(this.customerFilter.toLowerCase());

        const notSelected = customer.id != this.customer?.id;

        return matchesFilter && notSelected;
      })
      .sort((a, b) => {
        const aName = `${a.department}, ${a.province?.shortName ?? 'ZZ'}`.toLowerCase();
        const bName = `${b.department}, ${b.province?.shortName ?? 'ZZ'}`.toLowerCase();

        return aName.localeCompare(bName);
      })
  }

  /** An array of all customers */
  public allCustomers: Customer[] = [];

  /** lifecyle hook run on component creation */
  public async created() {
    this.allCustomers = await this.getCustomers();
    this.$forceUpdate();
  }

  /** Get an array of all customers */
  public async getCustomers(): Promise<Customer[]> {
    let customers = [];

    const allCustomersJson = await axios.get("/api/customers/full").then(resp => resp.data);

    for (const customerJson of allCustomersJson) {
      customers.push(
        Customer.fromJson(customerJson)
      );
    }

    return customers;
  }

  /** Emit a change-customer event that other components can listen for */
  public changeCustomer(customer: Customer) {
    this.$emit("change-customer", customer);
  }

  /** Emit a remove-customer event that other components can listen for */
  public removeCustomer() {
    this.$emit("change-customer", null);
  }
}

import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        // text colours
        darktext: "#030409",
        lighttext: "#e9eaee",

        // background colours
        lightbackground: "#e9eaee",
        mediumbackground: "#bdbfcd",
        darkbackground: "#030409",

        // primary colours from brand guidelines
        blue: "#222a57",
        red: "#e52224",
        yellow: "#f5c20d",
        lavender: "#9094ab",

        // same as "blue"
        primary: "#222a57",
        // same as "lavendar"
        secondary: "#9094ab",

        highlight: "#fcedb6",

        // same as "red"
        error: "#E63D37",
        info: "#2196F3",
        success: "#4CAF50",
        // same as "yellow"
        warning: "#FFC107"
      }
    }
  },
  icons: {
    iconfont: "mdi"
  }
});

import axios from "axios";

export interface ModuleConstructorOptions {
  id?: number | null;
  name?: string;
  description?: string;
  price?: number;
  serviceCost?: number;
  altPrice?: null | number;
  altServiceCost?: null | number;
  quantity?: number;
  allowedProvinces?: number[];
  usesPercent?: boolean;
  isArchived?: boolean;
  percent?: number;
}

export class Module {
  public id: number | null;
  public name: string;
  public description: string;
  public price: number;
  public _serviceCost: number;
  public altPrice: null | number;
  public altServiceCost: null | number;
  public quantity: number;
  public allowedProvinces: number[];
  public usesPercent: boolean;
  public isArchived: boolean;
  public percent: number;

  constructor(
    options?: ModuleConstructorOptions,
  ) {

    this.id = options?.id ?? null;
    this.name = options?.name ?? "New Module";
    this.description = options?.description ?? "";
    this.price = options?.price ?? 0;
    this._serviceCost = options?.serviceCost ?? 0;
    this.altPrice = options?.altPrice ?? null;
    this.altServiceCost = options?.altServiceCost ?? null;
    this.quantity = options?.quantity ?? 1;
    this.allowedProvinces = options?.allowedProvinces ?? [];
    this.usesPercent = options?.usesPercent ?? true;
    this.isArchived = options?.isArchived ?? false;
    this.percent = options?.percent ?? 0;
  }

  public static fromJson(modJson: any, percent: number): Module {
    return new Module({
      id: modJson.id,
      name: modJson.mod_name,
      description: modJson.mod_description,
      price: modJson.price,
      serviceCost: modJson.service_cost,
      altPrice: modJson.alt_price,
      altServiceCost: modJson.alt_service_cost,
      quantity: modJson.quantity,
      allowedProvinces: modJson.allowed_provinces,
      usesPercent: modJson.uses_percent,
      isArchived: modJson.is_archived,
      percent: percent,
    })
  }

  public intoJson(): any {
    return {
      id: this.id,
      mod_name: this.name,
      mod_description: this.description,
      price: this.price,
      service_cost: this._serviceCost,
      alt_price: this.altPrice,
      alt_service_cost: this.altServiceCost,
      quantity: this.quantity,
      allowed_provinces: this.allowedProvinces,
      uses_percent: this.usesPercent,
      is_archived: this.isArchived,
    }
  }

  public static async getById(id: number): Promise<Module> {
    const modJson = axios.get(`/api/modules/full/${id}`).then((resp) => resp.data);
    const percent = axios.get("/api/service_percent").then((resp) => resp.data);

    return Module.fromJson(await modJson, await percent);
  }

  public async save() {
    await axios.put(`/api/modules/full`, this.intoJson());
  }

  public async delete(): Promise<void> {
    await axios.delete(`/api/module_allows_provinces?module_id=${this.id}`);
    await axios.delete(`/api/modules/${this.id}`);
  }

  public async updatePercent(): Promise<void> {
    const percent = await axios.get("/api/service_percent").then((resp) => resp.data);

    this.percent = percent;
  }

  public get serviceCost(): number {
    if (this.usesPercent) {
      return Math.ceil(this.price * (this.percent / 100.0));
    } else {
      return this._serviceCost;
    }
  }

  public set serviceCost(value: number) {
    this._serviceCost = value;
  }

  public get fullPrice(): number {
    return this.price * this.quantity
  }

  public get fullService(): number {
    return this.serviceCost * this.quantity
  }

  public get discountedPrice(): number {
    if (this.altPrice !== null) {
      return this.altPrice * this.quantity;
    } else {
      return this.price * this.quantity;
    }
  }

  public get discountedService(): number {
    if (this.altServiceCost !== null) {
      return this.altServiceCost * this.quantity;
    } else {
      return this.serviceCost * this.quantity;
    }
  }
}

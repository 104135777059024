import Vue from "vue";
import Vuex from "vuex";

import { Module, VuexModule, Mutation } from "vuex-module-decorators";

Vue.use(Vuex);

@Module({ name: "mymod" })
export class MyModule extends VuexModule {
  public isAdmin: boolean = true;

  @Mutation public setAdmin(): void {
    this.isAdmin = true;
  }

  @Mutation public removeAdmin(): void {
    this.isAdmin = false;
  }
}

export const store = new Vuex.Store({
  modules: {
    myMod: MyModule
  }
});

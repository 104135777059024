import axios from "axios";

export interface ProvinceConstructorOptions {
  id?: number | null,
  shortName?: string,
  longName?: string,
  taxName?: string,
  taxRate?: number
}

export class Province {
  public id: number | null;
  public shortName: string;
  public longName: string;
  public taxName: string;
  public taxRate: number;

  constructor(
    options?: ProvinceConstructorOptions,
  ) {
    this.id = options?.id ?? null;
    this.shortName = options?.shortName ?? "";
    this.longName = options?.longName ?? "New Province";
    this.taxName = options?.taxName ?? "";
    this.taxRate = options?.taxRate ?? 0;
  }

  public static async getById(id: number): Promise<Province> {
    const provJson = await axios
      .get(`/api/provinces/${id}`)
      .then(resp => resp.data);

    return Province.fromJson(provJson);
  }

  public static fromJson(provJson: any): Province {
    return new Province({
      id: provJson.id,
      shortName: provJson.short_name,
      longName: provJson.long_name,
      taxName: provJson.tax_name,
      taxRate: provJson.tax_rate,
    });
  }

  public intoJson(): any {
    return {
      id: this.id,
      short_name: this.shortName,
      long_name: this.longName,
      tax_name: this.taxName,
      tax_rate: this.taxRate,
    };
  }

  public static async getByAbbrev(abbrev: string): Promise<Province> {
    const provJson = await axios
      .get(`/api/provinces/${abbrev}`)
      .then(resp => resp.data);

    return this.fromJson(provJson);
  }
}

import axios from "axios";
import { Module } from "./module";
import { Customer } from "./customer";

export interface PlanConstructorOptions {
  id?: number,
  customer?: Customer | null,
  notes?: string,
  isTemplate?: boolean,
  planName?: string,
  modules?: Module[],
  compareTemplates?: Plan[],
  lastEdit?: Date,
  priceModifier?: number,
  serviceModifier?: number,
  customerNotes?: string,
  isArchived?: boolean,
}

export class Plan {
  public id: number | null;
  public customer: Customer | null;
  public notes: string;
  public isTemplate: boolean;
  public planName: string;
  public modules: Module[];
  public compareTemplates: Plan[];
  public lastEdit: Date;
  public priceModifier: number;
  public serviceModifier: number;
  public customerNotes: string;
  public isArchived: boolean;

  constructor(
    options?: PlanConstructorOptions,
  ) {
    this.id = options?.id ?? null;
    this.customer = options?.customer ?? null;
    this.notes = options?.notes ?? "";
    this.isTemplate = options?.isTemplate ?? false;
    this.planName = options?.planName ?? "New Plan";
    this.modules = options?.modules ?? [];
    this.compareTemplates = options?.compareTemplates ?? [];
    this.lastEdit = options?.lastEdit ?? new Date();
    this.priceModifier = options?.priceModifier ?? 0;
    this.serviceModifier = options?.serviceModifier ?? 0;
    this.customerNotes = options?.customerNotes ?? "";
    this.isArchived = options?.isArchived ?? false;
  }

  get price(): number {
    let s = 0;
    for (const fp2Module of this.modules) {
      s += fp2Module.fullPrice;
    }
    return s;
  }

  get serviceCost(): number {
    let s = 0;
    for (const fp2Module of this.modules) {
      s += fp2Module.fullService;
    }
    return s;
  }

  get discountedPrice(): number {
    let s = 0;
    for (const fp2Module of this.modules) {
      s += fp2Module.discountedPrice;
    }
    return s + this.priceModifier;
  }

  get discountedService(): number {
    let s = 0;
    for (const fp2Module of this.modules) {
      s += fp2Module.discountedService;
    }
    return s + this.serviceModifier;
  }

  get discountedPriceTaxes(): number {
    if (this.customer !== null && this.customer.province !== null) {
      return this.discountedPrice * (this.customer.province.taxRate / 100);
    } else {
      return 0;
    }
  }

  get discountedServiceTaxes(): number {
    if (this.customer !== null && this.customer.province !== null) {
      return this.discountedService * (this.customer.province.taxRate / 100);
    } else {
      return 0;
    }
  }

  public sortModules(): void {
    this.modules.sort((a: Module, b: Module) => {
      return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
    });
  }

  public static async getByHashId(hashId: string): Promise<Plan | null> {
    const planExists: boolean = await axios
      .get(`/api/valid-hash?hash_id=${hashId}`)
      .then(resp => resp.data);

    if (planExists) {
      let planId: number = await axios
        .get(`/api/hash-decode?hash_id=${hashId}`)
        .then(resp => {
          return (planId = resp.data);
        });

      return this.getById(planId);
    } else {
      return null;
    }
  }

  public static async getHashId(planId: number): Promise<string> {
    const hashId: string = await axios
      .get(`/api/hash-encode?plan_id=${planId}`)
      .then(resp => resp.data);

    return hashId;
  }

  public static fromJson(planJson: any, percent: number): Plan {
    let modules = [];
    for (const modJson of planJson.modules) {
      modules.push(Module.fromJson(modJson, percent));
    }

    let templates = [];
    for (const templateJson of planJson.compare_templates) {
      templates.push(Plan.fromJson(templateJson, percent))
    }

    return new Plan({
      id: planJson.id,
      customer: planJson.customer ? Customer.fromJson(planJson.customer) : null,
      notes: planJson.notes,
      isTemplate: planJson.is_template,
      planName: planJson.plan_name,
      modules: planJson.modules.map(
        (moduleJson: any) => Module.fromJson(moduleJson, percent)
      ),
      compareTemplates: planJson.compare_templates.map(
        (templateJson: any) => Plan.fromJson(templateJson, percent)
      ),
      lastEdit: new Date(planJson.last_edit),
      priceModifier: planJson.price_modifier,
      serviceModifier: planJson.service_modifier,
      customerNotes: planJson.customer_notes,
      isArchived: planJson.is_archived,
    })
  }

  public getModuleById(moduleId: number): Module | null {
    return this.modules.find((m) => m.id === moduleId) ?? null;
  }

  public intoJson(): any {
    return {
      id: this.id,
      customer: this.customer ? this.customer.intoJson() : null,
      notes: this.notes,
      is_template: this.isTemplate,
      plan_name: this.planName,
      last_edit: this.lastEdit,
      modules: this.modules.map(module => module.intoJson()),
      compare_templates: this.compareTemplates.map(template => template.intoJson()),
      price_modifier: this.priceModifier,
      service_modifier: this.serviceModifier,
      customer_notes: this.customerNotes,
      is_archived: this.isArchived,
    }
  }

  public static async getById(id: number): Promise<Plan | null> {
    const fullPlanJson = axios.get(`/api/plans/full/${id}`).then((resp) => resp.data);
    const percent = axios.get("/api/service_percent").then((resp) => resp.data);

    return this.fromJson(await fullPlanJson, await percent);
  }

  public async save() {
    await axios.put(`/api/plans/full`, this.intoJson());
  }

  public async delete(): Promise<void> {
    await axios.delete(`/api/compare_templates?plan_id=${this.id}`);
    await axios.delete(`/api/plan_modules?plan_id=${this.id}`);
    await axios.delete(`/api/plans/${this.id}`);
  }
}













import { Component, Prop, Vue, Emit } from "vue-property-decorator";

@Component
export default class ListToolbar extends Vue {
  @Prop({ default: "" }) public title!: string;

  public newItem(): void {
    this.$emit("new-item");
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[(_vm.customer !== null)?_c('v-flex',[_c('v-layout',{attrs:{"column":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('label',[_vm._v("Department:")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('p',[_vm._v(_vm._s(_vm.customer.department))])])],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('label',[_vm._v("Province:")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('p',[_vm._v(_vm._s(_vm.customer.province ? _vm.customer.province.longName : 'No Province'))])])],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('label',[_vm._v("Contact Name:")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('p',[_vm._v(_vm._s(_vm.customer.contactName))])])],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('label',[_vm._v("Contact Email:")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('p',[_vm._v(_vm._s(_vm.customer.contactEmail))])])],1)],1)],1):_c('v-flex',[_c('v-alert',{staticClass:"mb-0",attrs:{"icon":"mdi-information"}},[_c('h3',[_vm._v("No customer has been set for this plan.")]),_c('p',[_vm._v("Press the button to choose one.")])])],1),(_vm.myMod.isAdmin)?_c('v-flex',{attrs:{"shrink":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-dialog',{attrs:{"scrollable":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"fab":"","small":"","depressed":"","color":"secondary"}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,true),model:{value:(_vm.changeCustomerDialogOpen),callback:function ($$v) {_vm.changeCustomerDialogOpen=$$v},expression:"changeCustomerDialogOpen"}},[_c('v-card',[_c('v-card-title',[_vm._v("Select Customer")]),_c('v-divider'),_c('v-card-text',[_c('v-text-field',{attrs:{"search":"","label":"Filter","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.customerFilter),callback:function ($$v) {_vm.customerFilter=$$v},expression:"customerFilter"}}),_c('v-list',_vm._l((_vm.filteredCustomers),function(item){return _c('v-list-item',{key:item.id,on:{"click":function($event){_vm.changeCustomer(item); _vm.changeCustomerDialogOpen = false; _vm.customerFilter = ''}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.department)+", "+_vm._s(item.province ? item.province.shortName : 'No Province')+" ")])],1)],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.changeCustomerDialogOpen = false; _vm.customerFilter = ''}}},[_vm._v("Close")])],1)],1)],1)]}}],null,false,1475481082)},[_c('span',[_vm._v("Change customer")])])],1),(_vm.customer)?_c('v-flex',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"fab":"","small":"","depressed":"","color":"secondary"},on:{"click":_vm.removeCustomer}},on),[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}],null,false,1570192952)},[_c('span',[_vm._v("Remove customer from this plan")])])],1):_vm._e(),(_vm.customer)?_c('v-flex',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"fab":"","small":"","depressed":"","color":"secondary"},on:{"click":function($event){return _vm.$router.push(("/customers/" + (_vm.customer && _vm.customer.id)))}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,false,4108955652)},[_c('span',[_vm._v("Go to customer edit page")])])],1):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }




















































































































































































































































































































































































































import axios from "axios";

import { Component, Vue, Watch } from "vue-property-decorator";
import { Plan, Module, Customer } from "@/scripts/fp2";
import CustomerSummary from "@/components/CustomerSummary.vue";
// import { isIntegerRule } from '@/scripts/fp2';

@Component({
  components: {
    CustomerSummary
  }
})
export default class PlanView extends Vue {
  public myMod = this.$store.state.myMod;

  public stillLoading: boolean = true;
  public plan: Plan | null = null;
  public quotePath: string = "";

  public allModules: Module[] = [];
  public templates: Plan[] = [];

  public dialog: boolean = false;
  public editedItem: Module = new Module();

  private numFormatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD"
  });

  public addModuleDialogOpen: boolean = false;

  public moduleFilter: string = "";

  public copyTemplateDialogOpen: boolean = false;

  public templateFilter: string = "";

  get filteredModules() {
    return this.allModules
      .filter(module => {
        const matchesFilter = `${module.name}`
          .toLowerCase()
          .includes(this.moduleFilter.toLowerCase());

        const notSelected = !this.plan?.modules
          .map((module) => module.id)
          .includes(module.id);

        return matchesFilter && notSelected;
      })
      .sort((a, b) => {
        const aName = `${a.name}`.toLowerCase();
        const bName = `${b.name}`.toLowerCase();

        return aName.localeCompare(bName);
      })
  }

  get filteredTemplates() {
    return this.templates
      .filter((template) => {
        const matchesFilter = `${template.planName}`
          .toLowerCase()
          .includes(this.templateFilter.toLowerCase());

        return matchesFilter && template.isTemplate;
      })
      .sort((a, b) => {
        const aName = `${a.planName}`.toLowerCase();
        const bName = `${b.planName}`.toLowerCase();

        return aName.localeCompare(bName);
      })
  }

  public isIntegerRule(n: any) {
      return Number.isInteger(Number(n)) || 'Must be an Integer';
  }

  get customerTaxName() {
    return this.plan?.customer?.province?.taxName ?? "No Customer Tax Name"
  }

  @Watch("plan.modules", { deep: true })
  public onModulesChanged(val1: any, val2: any) {
    // called when any value in plan.modules changes. Overkill, but only way to force
    // price values to null when form inputs return empty string.
    if (this.plan !== null) {
      for (const mod of this.plan.modules) {
        if ((mod.altPrice as string | null | number) === "") {
          mod.altPrice = null;
        }
        if ((mod.altServiceCost as string | null | number) === "") {
          mod.altServiceCost = null;
        }
      }
    }
  }

  @Watch("plan.priceModifier", {deep: true})
  public onPriceModifierChange(val1: any, val2: any) {
    if (this.plan && ((this.plan.priceModifier as string | null | number) === '')) {
      this.plan.priceModifier = 0;
    }
  }

  @Watch("plan.serviceModifier", {deep: true})
  public onServiceModifierChange(val1: any, val2: any) {
    if (this.plan && ((this.plan.serviceModifier as string | null | number) === '')) {
      this.plan.serviceModifier = 0;
    }
  }

  public async getQuotePath() {
    this.quotePath = "/quotes/" + (await Plan.getHashId(parseInt(this.$route.params.planId, 10)));
  }

  public changeCustomer(customer: Customer | null): void {
    if (this.plan !== null) {
      this.plan.customer = customer;
    } else {
      alert("cannot change customer while plan === null");
    }
  }

  public async copyFromTemplate(template: Plan): Promise<void> {
    // copy modules from template to this plan
    if (this.plan !== null) {
      const templateCopy = template.id ? await Plan.getById(template.id) : null;
      if (templateCopy !== null) {
        // copy standard modules and plan-wide price modifiers
        // don't copy module alt pricing.
        this.plan.modules = templateCopy.modules;
        for (const module of this.plan.modules) {
          module.altPrice = null;
          module.altServiceCost = null;
        }
        this.plan.priceModifier = templateCopy.priceModifier;
        this.plan.serviceModifier = templateCopy.serviceModifier;
      } else {
        alert("template id is invalid");
      }
    } else {
      alert("cannot copy modules from template while plan === null");
    }
  }

  public editItem(item: Module) {
    this.editedItem = item;
    this.dialog = true;
  }

  public deleteItem(item: Module) {
    if (this.plan !== null) {
      const index = this.plan.modules.indexOf(item);
      if (index !== -1) {
        this.plan.modules.splice(index, 1);
      }
    } else {
      alert("cannot delete module from plan when plan === null");
    }
  }

  public doneEdit() {
    this.dialog = false;
  }

  public addModule(item: Module): void {
    if (this.plan !== null) {
      for (const module of this.plan.modules) {
        if (item.id === module.id) {
          alert("That Module is already in the list.");
          return;
        }
      }

      this.plan.modules.push(item);
      this.plan.sortModules();
    } else {
      alert("cannot add module while plan === null");
    }
  }

  public async getModules() {
    const modsJson = await axios.get("/api/modules/full").then(resp => resp.data);
    const percent = await axios.get("/api/service_percent").then((resp) => resp.data);

    let modules = [];
    for (const modJson of modsJson) {
      modules.push(Module.fromJson(modJson, percent));
    }

    this.allModules = modules;
  }

  public async getPlan() {
    this.stillLoading = true;
    const id = this.$route.params.planId;
    this.plan = await Plan.getById(parseInt(id, 10));
    this.plan && this.plan.sortModules();
    this.stillLoading = false;
  }

  public asDollar(n: number) {
    return this.numFormatter.format(n);
  }

  public get unusedModules(): Module[] {
    if (this.plan !== null) {
      const result: Module[] = [];
      for (const module of this.allModules) {

        const isUnused = !this.plan.modules.map(m => m.id).includes(module.id);
        const customerProvinceId: number | null = this.plan?.customer?.province?.id ?? null;
        const isAllowed = customerProvinceId ? module.allowedProvinces.includes(customerProvinceId) : true;

        if (isUnused && isAllowed) {
          result.push(module);
        }
      }
      result.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
      return result;
    } else {
      return [];
    }
  }

  public async getTemplates(): Promise<void> {
    const templatesJson = await axios.get(`/api/plans/full`).then(resp => resp.data);
    const percent = await axios.get("/api/service_percent").then((resp) => resp.data);

    const results = [];
    for (const tJson of templatesJson) {
      results.push(Plan.fromJson(tJson, percent));
    }
    // We want templates to be earlier in the list than plans
    results.sort((a, b) => a.isTemplate < b.isTemplate ? 1 : -1);
    // have to type assert because typescript thinks filter is a fn(Array<Plan | null>) => Array<Plan | null>,
    // but it is actually a fn(Array<Plan | null>) => Array<Plan>.
    // We need to make sure we aren't including this.plan here
    this.templates = results.filter((val) =>
      val !== null &&
      this.plan !== null &&
      val.id !== this.plan.id
    ) as Plan[];
  }

  public onPriceInput(_val: any) {
    return 99;
  }

  public onServiceCostInput(_val: any) {
    return 99;
  }

  public async savePlan(): Promise<void> {
    if (this.plan !== null) {
      await this.plan.save();
      this.$router.go(-1);
    }
  }

  async created() {
    await Promise.all([
      this.getPlan(),
      this.getTemplates(),
      this.getModules(),
      this.getQuotePath(),
    ]);

    // If I don't include this line, the customer summary doesn't draw properly.
    this.$forceUpdate();
  }
}

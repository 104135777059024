








































































































































































import axios from "axios";

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Plan, Module, Customer, Province } from "@/scripts/fp2";
import { MyModule } from "@/store";
import CustomerSummary from "@/components/CustomerSummary.vue";

@Component({
  components: {
    CustomerSummary
  }
})
export default class Quote extends Vue {
  public myMod = this.$store.state.myMod;

  public plan: Plan | null = null;

  public templates: Plan[] = [];

  public modules: Module[] = [];

  public stillLoading: boolean = true;

  public origin: string = window.location.origin;

  private numFormatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD"
  });

  get customerTaxName(): string {
    return this.plan?.customer?.province?.taxName ?? "No Customer Tax Name"
  }

  get customerTaxRate(): number {
    return this.plan?.customer?.province?.taxRate ?? 0.0
  }

  public isModuleDiscounted(plan: Plan, module: Module): boolean {
    if (!module.id) {
      alert("cannot check discount status of module with id of null");
      return false
    }

    const hasPriceDiscount = plan.getModuleById(module.id)?.discountedPrice != module.fullPrice;
    const hasServiceDiscount = plan.getModuleById(module.id)?.discountedService != module.fullService;

    return hasPriceDiscount || hasServiceDiscount
  }

  public asDollar(n: number) {
    return this.numFormatter.format(n);
  }

  public planIncludesModule(plan: Plan, module: Module): boolean {
    if (!module.id) {
      return false;
    } else {
      return plan.modules.map(m => m.id).includes(module.id);
    }
  }

  public toggleModule(module: Module) {
    if (!module.id) {
      alert("cannot toggle module with id of null");
      return
    }

    if (this.plan) {
      if (this.planIncludesModule(this.plan, module)) {
        this.removeModuleFromPlan(module);
      } else {
        this.addModuleToPlan(module);
      }
    } else {
      alert("cannot toggle module while plan is null");
    }
  }

  public removeModuleFromPlan(module: Module) {
    if (!module.id) {
      alert("Cannot remove module with id of null");
      return
    }
    if (!this.plan) {
      alert("Cannot remove module while plan === null");
      return
    }

    const index = this.plan.modules.map(m => m.id).indexOf(module.id);
    if (index !== -1) {
      this.plan.modules.splice(index, 1);
    }
  }

  public addModuleToPlan(module: Module) {
    if (!module.id) {
      alert("Cannot remove module with id of null");
      return
    }
    if (!this.plan) {
      alert("Cannot remove module while plan === null");
      return
    }

    this.plan.modules.push(module);
    this.plan.sortModules();
  }

  async created() {
    this.stillLoading = true;

    this.plan = await Plan.getByHashId(this.$route.params.hashId);

    if (this.plan === null) {
      // hashId is invalid or doesn't map to a valid planId
      this.stillLoading = false;
      return;
    }

    const templateIds: number[] = await axios
      .get(`/api/compare_templates?plan_id=${this.plan.id}`)
      .then(resp => {
        return resp.data.map(
          (ct: { plan_id: number; template_id: number }) => ct.template_id
        );
      });

    let templates = [];
    for (const tId of templateIds) {
      templates.push(await Plan.getById(tId));
    }
    // type assertion because typescript is dumb and can't tell that filter removes all the nulls
    templates = templates.filter(val => val !== null) as Plan[];
    // smallest to largest
    templates = templates.sort((a, b) => b.discountedPrice - a.discountedPrice);
    // we want plans to be shown on the left.
    // note: all browsers post ~2017 implement sort as stable.
    // However, browsers before that might not, which would mean this
    // second sort would cause us to lose the ordering on price.
    templates = templates.sort((a, b) => a.isTemplate > b.isTemplate ? 1 : -1);
    this.templates = templates;

    // make the set of unique modules that cover all the templates and the plan
    // will add this plan's modules first so that alt prices are preserved
    for (const plan of [this.plan].concat(this.templates)) {
      for (const mod of plan.modules) {
        if (!this.modules.map(m => m.id).includes(mod.id)) {
          this.modules.push(mod);
        }
      }
    }
    // largest to smallest
    this.modules.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

    document.title = "FP2 Pricing - " + this.$route.params.hashId;

    this.stillLoading = false;
  }
}


















































import { Component, Prop, Vue } from "vue-property-decorator";
import { Module } from "@/scripts/fp2";

@Component
export default class ModuleListItem extends Vue {
  @Prop() public fp2Module!: Module;

  public async editItem() {
    this.$router.push(`/modules/${this.fp2Module.id}`);
  }

  public async deleteItem() {
    await this.fp2Module.delete().catch(error => {
      if (error.response.status === 409 /* conflict */) {
        alert(
          "Cannot delete this module because plans still exist that use it"
        );
      }
    });

    this.$emit("item-deleted");
  }

  public async archiveItem() {
    if (confirm("Are you sure you want to archive this module?")) {
      this.fp2Module.isArchived = true;

      await this.fp2Module.save().catch((error) => {
        alert("There was an error archiving this module");
      });

      this.$emit("item-archived");
    }
  }
}

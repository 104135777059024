







































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { MyModule } from "@/store";

import axios from "axios";

@Component
export default class Navbar extends Vue {
  public myMod = this.$store.state.myMod;

  public loggedIn: boolean = false;

  @Watch("$route")
  public onRouteChange(value: any, oldValue: any) {
    this.checkLoggedIn();
  }

  public async checkLoggedIn() {
    this.loggedIn = await axios
      .get(`/api/logged-in`, { withCredentials: true })
      .then(resp => resp.data);
  }

  public async logout() {
    await axios.post("/api/logout");
    this.$router.go(0);
  }

  public async created() {
    await this.checkLoggedIn();
  }
}

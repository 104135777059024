










































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";

@Component
export default class Home extends Vue {
  public quoteNumber: string = "";

  public lastQuoteNumber: string = "";
  public badQuoteAlert: boolean = false;

  public async goToQuote(qNum: string) {
    const exists: boolean = await axios
      .get(`/api/valid-hash?hash_id=${qNum}`)
      .then(resp => resp.data);

    if (exists) {
      this.$router.push("/quotes/" + qNum);
    } else {
      this.lastQuoteNumber = this.quoteNumber;
      this.badQuoteAlert = true;
    }
  }
}

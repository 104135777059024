import Vue from "vue";
import Router from "vue-router";

import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import NotFound from "./views/NotFound.vue";

import Plans from "@/views/Plans.vue";
import Customers from "@/views/Customers.vue";
import Modules from "@/views/Modules.vue";

import Plan from "@/views/Plan.vue";
import Customer from "@/views/Customer.vue";
import Module from "@/views/Module.vue";

import GlobalSettings from "@/views/GlobalSettings.vue";

import Quote from "@/views/Quote.vue";
import axios from "axios";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        isPublic: true
      }
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        isPublic: true,
        onlyWhenLoggedOut: true
      }
    },
    {
      path: "/global-settings",
      name: "global settings",
      component: GlobalSettings,
    },
    {
      path: "/plans",
      name: "plans",
      component: Plans
    },
    {
      path: "/plans/:planId",
      name: "plan",
      component: Plan
    },
    {
      path: "/modules",
      name: "modules",
      component: Modules
    },
    {
      path: "/modules/:moduleId",
      name: "module",
      component: Module
    },
    {
      path: "/customers",
      name: "customers",
      component: Customers
    },
    {
      path: "/customers/:customerId",
      name: "customer",
      component: Customer
    },
    {
      path: "/quotes/:hashId",
      name: "quote",
      component: Quote,
      meta: {
        isPublic: true
      }
    },
    {
      path: "*",
      name: "not-found",
      component: NotFound,
      meta: {
        isPublic: true
      }
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.isPublic);
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  );

  if (!isPublic) {
    const loggedIn: boolean = await axios
      .get(`/api/logged-in`, { withCredentials: true })
      .then(resp => resp.data);

    if (loggedIn) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;


















































import axios from "axios";

import { Component, Vue } from "vue-property-decorator";

@Component
export default class GlobalSettings extends Vue {
  public stillLoading: boolean = true;

  public globalSettings = {
    servicePercent: 0,
  };

  public get rules() {
    return {
      validPrice: (n: string | number) =>
        Number.isInteger(Number(n)) || "Must be an Integer"
    };
  }

  public async getServicePercent() {
    this.stillLoading = true;
    await axios.get("/api/service_percent").then((resp) => {
      this.globalSettings.servicePercent = resp.data;
    })
    this.stillLoading = false;
  };

  public async saveSettings() {
    axios.put(
      "/api/service_percent",
      this.globalSettings.servicePercent,
      {headers: {'Content-Type': 'application/json'}}
    ).then((resp) => {
      alert("Saved Successfully!");
    }).catch((error) => {
      alert(error);
    })
  }

  async created() {
    await this.getServicePercent();
  }
}






































































import axios from "axios";

import { Component, Vue, Prop } from "vue-property-decorator";
import { Plan } from "@/scripts/fp2";

@Component
export default class PlanListItem extends Vue {
  @Prop() public plan!: Plan;

  /** The path to the quote page for this plan. */
  // Requires an api call to determine, which is why we store it.
  public quotePath: string = "";

  /** Lifecycle hook that runs on component creation */
  public async created() {
    if (!this.plan.id) {
      alert("Cannot create quote path for plan with no id");
      return
    }
    this.quotePath = "/quotes/" + (await Plan.getHashId(this.plan.id));
  }

  /** Go to the quote page */
  public async viewQuote() {
    this.$router.push(`${this.quotePath}`)
  }

  /** Go to the edit page */
  public async editPlan() {
    this.$router.push(`/plans/${this.plan.id}`)
  }

  /** Delete the plan */
  public async deletePlan() {
    if (confirm("Are you sure you want to delete this plan?")) {
      await this.plan.delete().catch((error) => {
        alert("There was an error deleting the plan");
      });

      this.$emit("item-delete");
    }
  }

  /** Archive the plan */
  public async archivePlan() {
    if (confirm("Are you sure you want to archive this plan?")) {
      this.plan.isArchived = true;
      await this.plan.save().catch((error) => {
        alert("There was an error archiving the plan");
      });

      this.$emit("item-archived");
    }
  }
}















































































import axios from "axios";

import { Component, Vue, Watch } from "vue-property-decorator";
import { Module, Province } from "@/scripts/fp2";

@Component
export default class ModuleView extends Vue {
  public myMod = this.$store.state.myMod;

  public stillLoading: boolean = true;
  public module: Module = new Module();

  public allProvinces: Province[] = [];

  public get rules() {
    return {
      validPrice: (n: string | number) =>
        (n !== "" && Number.isInteger(Number(n))) || "Must be an Integer"
    };
  }

  @Watch("module.usesPercent")
  onUsesPercentChanged(_value: boolean) {
    this.module.updatePercent();
  }

  public async getProvinces() {
    const provinces = await axios.get(`/api/provinces`).then(resp => resp.data);
    for (const provJson of provinces) {
      this.allProvinces.push(Province.fromJson(provJson));
    }
  }

  public async getModule() {
    this.stillLoading = true;
    const id = this.$route.params.moduleId;
    this.module = await Module.getById(parseInt(id, 10));
    this.stillLoading = false;
  }

  public async saveModule(): Promise<void> {
    if (
      this.rules.validPrice(this.module.price) === true &&
      this.rules.validPrice(this.module.serviceCost) === true
    ) {
      await this.module.save();
      this.$router.go(-1);
    } else {
      alert("Price and Service Cost must be Integers");
    }
  }

  async created() {
    await axios.all([
      this.getModule(),
      this.getProvinces(),
    ])
  }
}

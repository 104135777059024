



























import { Component, Vue } from "vue-property-decorator";
import ListToolbar from "@/components/ListToolbar.vue";
import CustomerListItem from "@/components/CustomerListItem.vue";

import axios from "axios";

import { Customer } from "@/scripts/fp2";

@Component({
  components: {
    ListToolbar,
    CustomerListItem
  }
})
export default class Customers extends Vue {
  public stillLoading: boolean = true;

  public customers: Customer[] = [];

  public async newCustomer() {
    const custID = await axios
      .put("/api/customers/full", new Customer().intoJson())
      .then(resp => resp.data);

    this.$router.push(`/customers/${custID}`);
  }

  public async getCustomers() {
    this.stillLoading = true;

    this.customers = [];

    const custsJson = await axios.get("/api/customers/full").then(resp => resp.data);

    for (const custJson of custsJson) {
      this.customers.push(Customer.fromJson(custJson));
    }

    this.customers.sort((a, b) => {
      if (a.department === null) {
        return -1;
      } else if (b.department === null) {
        return 1;
      } else {
        return a.department.toLowerCase() > b.department.toLowerCase() ? 1 : -1;
      }
    });

    this.stillLoading = false;
  }

  async created() {
    await this.getCustomers();
  }
}
